<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon"> 
      <v-row justify="center">
        <v-col>
          <v-select
            v-model="selectedDatagroup"
            :items="objects"
            item-text="name"
            :item-value="getItemValue"
            dense
            outlined
            clearable
            background-color="white"
            hide-details
            placeholder="Selecione um nome"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-btn 
            block color="success"  
            large 
            :disabled="!selectedDatagroup" 
            @click="loadResume()"
          >Buscar</v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <div v-for="item in items" :key="item.type">
      <v-row class="mb-3" v-if="items.length > 0">
        <v-col cols="12">
          <v-card rounded elevation="1">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col xxl="5" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Setor</span>
                        <h2>{{ item.sectorName }}</h2>
                      </div>
                    </v-col>
                    <v-col xxl="5" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Processo</span>
                        <h2>{{ item.nameActivity }}</h2>
                      </div>
                    </v-col>
                    <v-col xxl="4" xl="4" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Responsável</span>
                        <h3>{{ item.positionName }}</h3>
                      </div>
                    </v-col>
                    <v-col xxl="4" xl="4" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Local</span>
                        <h3>{{ item.placeName }}</h3>
                      </div>
                      <div>
                        <v-chip label class=" white--text" color="blue">{{ item.typeName }}</v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="mb-3 mt-3"></v-divider>

              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <span style="opacity: 0.5;">Dados</span>
                      <h3 :style="item.dataName && item.dataName.length > 35 ? 'word-wrap: break-word; max-width: 40%;' : ''">{{ formatDataName(item.dataName) }}</h3>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-row justify-space-between" >
                      <div>
                        <span style="opacity: 0.5;">Base Legal</span>
                        <h3 :style="item.legalName.length > 30 ? 'word-wrap: break-word; max-width: 75%;' : ''">{{ item.legalName }}</h3>
                      </div>
                      <div>
                        <span style="opacity: 0.5;">Consentimento</span>
                        <h3>{{ item.consentName }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'

export default {
  name: 'Dados',

  components: {
    TheTitle,
  },

  data: () => ({
    baseRoute: 'reports/dataMappingByHolder',
    title: 'Mapeamento de Dados por Titular',
    pageIcon: 'mdi-folder-account',
    items: [],
    selectedDatagroup: null,
    objects: [],
  }),

  methods: {
    loadData() {
      const url = `${baseApiUrl}/datagroup`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch('setLoadingInfo', null)
        })
    },

    getItemValue(item) {
      return `${item.id} ${item.idCompany}`
    },

    async loadResume() {
      if (!this.selectedDatagroup) {
        return
      }
      const [id, idCompany] = this.selectedDatagroup.split(' ')
      const url = `${baseApiUrl}/dataMappingByHolder?idDatagroup=${id}&idCompany=${idCompany}`
      this.$store.dispatch('setLoadingInfo', true)
      try {
        const res = await axios.get(url)
        this.items = res.data
      } catch (error) {
        showError(error)
      } finally {
        this.$store.dispatch('setLoadingInfo', null)
      }
    },

    formatDataName(item) {
      if (!item) return ''
      return item.split(',').join(', ')
    },
  },

  mounted() {
    this.loadData()
  },
}
</script>

<style>
.icon {
  cursor: pointer;
}
</style>
